var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-modal", {
        ref: "modal-edit-group-user-role",
        attrs: {
          id: "modal-edit-group-user-role",
          "footer-class": "flex-nowrap",
          "no-close-on-backdrop": "",
          "no-close-on-esc": "",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [_vm._v(_vm._s(_vm.$t("modals.editUser.title")))])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _vm.groupRoles.length || _vm.isSysAdmin
                  ? _c(
                      "div",
                      { staticClass: "mx-5 mb-4" },
                      [
                        _c("div", { staticClass: "mt-3 text-center" }, [
                          _c("p", [
                            _c("strong", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("modals.editUser.name")) +
                                  ": " +
                                  _vm._s(_vm.userObject.name || "-----")
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("modals.editUser.email")) +
                                  ": " +
                                  _vm._s(_vm.userObject.email) +
                                  " "
                              )
                            ])
                          ])
                        ]),
                        _c(
                          "b-dropdown",
                          {
                            attrs: {
                              id: "setRole",
                              "menu-class": "w-100 pb-2",
                              block: "",
                              text: _vm.dropdownText
                            },
                            model: {
                              value: _vm.role,
                              callback: function($$v) {
                                _vm.role = $$v
                              },
                              expression: "role"
                            }
                          },
                          _vm._l(_vm.groupRoles, function(r, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "w-100" },
                              [
                                _c(
                                  "b-dropdown-item-button",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.setRole(r)
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(r.name))]
                                ),
                                _c("b-dropdown-text", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t(`roles.${r.handle}`))
                                  )
                                ]),
                                index !== _vm.groupRoles.length - 1
                                  ? _c("b-dropdown-divider")
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: { click: _vm.cancelModal }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: { variant: "primary", block: "" },
                    on: { click: _vm.editUser }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("modals.editUser.validate")) + " "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }