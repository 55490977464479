import gql from 'graphql-tag';

export default gql`
query getGroupRoles {
  roles(order_by: {level: asc}, where: {_or: [{name: {_eq: "Account Manager"}}, {name: {_eq: "Administrator"}}, {name: {_eq: "Manager"}}]}) {
    role_id
    name
    handle
    level
    description
  }
}
`;
